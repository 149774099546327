<template>
  <b-modal
    ref="assignedClientsModal"
    v-model="modalcenter"
    modal
    size="xmd"
    header-class="p-0"
    no-close-on-backdrop
    header-bg-variant="transparent border-bottom border-bottom-2"
    modal-class="modal-primary"
    @hidden="hideModal"
    hide-footer
    scrollable
  >
    <template v-slot:modal-header>
      <ModalHeader
        :dataItem="dataItem"
        :title="'ASSIGNED CLIENT LIST'"
        @close="hideModal()"
      />
    </template>
    <validation-observer ref="form">
      <b-row style="margin-left: 15px">
        <b-col cols="12">
          <b-row>
            <b-form-group label="Select advisor" style="width: 40% !important">
              <validation-provider
                v-slot="{ errors }"
                name="module"
                rules="required"
              >
                <b-select
                  v-model="select_advisor"
                  :options="optionsAdvisor"
                  value-field="id"
                  text-field="value"
                  class="w-full { 'is-border-danger': errors[0] }"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-form-group>
            <b-button
              variant="primary"
              @click="assign()"
              style="
                height: 35px !important;
                margin-top: 27px !important;
                margin-left: 10px;
              "
            >
              <!-- <feather-icon
                icon="PlusCircleIcon"
                class="text-primary"
                v-b-tooltip.hover.right="'Assign'"
                size="25"
              >
              </feather-icon> -->
              Assign
            </b-button>
            <span
              style="margin-left: 15px !important"
              class="text-info"
              v-if="fromUsersModule"
            >
              * Remember you can close this and assign the clients to another
              advisor later (in the tab inactive)</span
            >
            <span
              class="text-info"
              v-else
              style="margin-top: 2.4rem; margin-left: 15px !important"
            >
              * Remember you can do it later</span
            >
          </b-row>
        </b-col>
      </b-row>
    </validation-observer>
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="filter"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refresh"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refsTable"
          small
          no-provider-filtering
          :items="init"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          class="overflow-y"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #head(selected)>
            <b-form-checkbox v-model="selectAll" @input="selectedAll" />
          </template>
          <template v-slot:cell(selected)="data">
            <b-form-group>
              <b-form-checkbox
                v-model="clients_status"
                :value="{
                  id: data.item.id,
                  client_id: data.item.client_id,
                }"
                class="mt-1"
              />
            </b-form-group>
          </template>
          <template #cell(created_at)="data">
            <div>
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>
<script>
import ModalHeader from "./ModalHeader.vue";
import vSelect from "vue-select";
import UsersService from "../../service/users.service";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalHeader,
    vSelect,
  },
  props: {
    dataItem: {
      type: Object,
    },
    fromUsersModule: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allClients: [],
      clients_status: [],
      selectAll: null,
      clients: [],
      select_advisor: null,
      optionsAdvisor: [
        {
          value: "All",
          id: null,
        },
      ],
      filter: [],
      modalcenter: false,
      recoveryMood: false,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      sortBy: "created_at",
      sortDesc: true,
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      isBusy: false,

      fields: [
        {
          key: "selected",
          label: "Name",
          class: "text-left",
          visible: true,
        },
        {
          key: "lead_name",
          label: "Name",
          class: "text-left",
          sortable: true,
          visible: true,
        },
        {
          key: "account",
          label: "account",
          visible: true,
        },

        {
          key: "email",
          label: "Email",
          visible: true,
        },
        {
          key: "mobile",
          label: "Mobile",
          visible: true,
        },
        {
          key: "created_at",
          label: "Creation date",
          visible: true,
          sortable: true,
        },
      ],
    };
  },
  async created() {
    this.modalcenter = true;
    await this.loadAdvisor();
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    selectedAll() {
      if (this.selectAll) {
        const nameCl = [];
        this.clients.map((dato) => {
          nameCl.push({
            id: dato.id,
            client_id: dato.client_id,
          });
        });
        const value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (!value) {
          this.allClients.push({
            currentPage: this.paginate.currentPage,
            array: nameCl,
          });
        }
      } else {
        const value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (value) {
          const index = this.allClients.indexOf(value);
          this.allClients.splice(index, 1);
        }
      }
      this.clients_status = [];
      for (let i = 0; i < this.allClients.length; i++) {
        this.clients_status = [
          ...this.clients_status,
          ...this.allClients[i].array,
        ];
      }
    },
    async assign() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          if (this.clients_status.length == 0) {
            this.showWarningSwal("check one or more records");
            return;
          }
          const confirm = await this.showConfirmSwal(
            "You want to assign the clients to the selected advisor"
          );
          if (confirm.value) {
            const params = {
              advisorId: this.select_advisor,
              data: this.clients_status,
              user_id: this.dataItem.user_id,
              user_session_id: this.currentUser.user_id,
              module_id: "",
            };
            params.module_id = this.fromUsersModule ? this.moduleId : 6;
            this.addPreloader();
            const response = await UsersService.updateClientForAdvisor(params);
            if (response.status == 200) {
              this.selectAll = null;
              this.removePreloader();
              this.refresh();
              const params = { id: this.dataItem.user_id };
              const response = await UsersService.getCountClientsOfAdvisor(
                params
              );
              let cantidad = response[0].cantidad;
              if (cantidad == 0) {
                this.$emit("refresh");
              }
            }
          }
        }
        // else {
        //   this.showWarningSwal("Select an advisor");
        //   return;
        // }
      } catch (e) {
        console.error(e);
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },

    async loadAdvisor(module = 6) {
      try {
        const resp = await UsersService.getAllAdvisorForCreditExpert({
          module_id: this.fromUsersModule ? this.moduleId : module,
        });
        this.optionsAdvisor = [
          ...this.optionsAdvisor,
          ...resp.map((item) => {
            return {
              id: item.id,
              value: item.name,
            };
          }),
        ];
      } catch (e) {
        console.error(e);
      }
    },
    refresh() {
      this.$refs.refsTable.refresh();
    },
    hideModal() {
      this.modalcenter = false;
      this.$refs['assignedClientsModal'].hide();
      this.$emit("hideModal");
    },

    async init(ctx) {
      let sort_By = 1;
      let sortDirection = "desc";
      if (ctx) {
        if (ctx.sortBy === "created_at") {
          sort_By = 5;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        }
      }
      try {
        const params = {
          user_id: this.dataItem.user_id,
          texto: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          orderby: sort_By,
          order: sortDirection,
        };
        const response = await UsersService.getClientsForAdvisor(params);
        this.startPage = response.data.from;
        this.paginate.currentPage = response.data.current_page;
        this.paginate.perPage = response.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.data.last_page;
        this.totalRows = response.data.total;
        this.toPage = response.data.to;
        this.items = response.data.data;
        this.clients = response.data.data;
        return response.data.data;
      } catch (e) {
        console.error(e);
        this.showErrorSwal(e);
      }
    },
  },
};
</script>
<style scoped></style>
